import {
  AssetType,
  CurrencyCode,
  OfferGroupStatus,
  OrderPosition,
  OrderStatus,
  OrderType,
} from "@/app/types/generated/graphql";

export const demoAskOrder = {
  id: "dd9b0dc3-ef67b906bf68",
  tenantId: "08cd2505-2195463332e8",
  accountId: "25ec0a20-a312f22e3467",
  assetType: AssetType.Rec,
  position: OrderPosition.Ask,
  status: OrderStatus.Open,
  initialVolume: 19,
  currentVolume: 19,
  completedOn: null,
  createdOn: "2024-08-30T03:58:26.545Z",
  orderNumber: "20240830-EVAF",
  allowCounterOffers: true,
  type: OrderType.Limit,
  price: {
    currencyCode: CurrencyCode.Usd,
    amount: "300",
  },
  updatedOn: "2024-08-30T03:58:27.167Z",
  attributes: {
    vintages: ["2021-BACK_HALF"],
    locations: ["BC"],
    fuelSources: ["WND"],
    eligibilities: ["il_utility"],
    projects: ["hxa5xf1v8wvx5tcvez113jwxeto24q7h"],
    certifications: [],
    commencementDate: "2003-09-16",
  },
  vintage: "",
  vintages: ["2021 - BH"],
  eligibilities: ["Illinois Utility"],
  location: "-",
  locations: ["British Columbia"],
  fuelSources: ["Wind"],
  project: "-",
  projects: ["secret evidence"],
  certifications: [],
  certificationsAndEligibilities: [
    {
      label: "certifications",
      value: [],
    },
    {
      label: "eligibilities",
      value: ["Illinois Utility"],
    },
  ],
};

export const counteredOrdersData = [
  {
    id: "c64a90f80-4d7f1b56372c",
    tenantId: "08cd2505-18d4-488b-a960-2195463332e8",
    accountId: "d098227a-44-672ea907255a",
    assetType: AssetType.Rec,
    position: OrderPosition.Ask,
    status: OrderStatus.Open,
    initialVolume: 5,
    currentVolume: 5,
    completedOn: null,
    createdOn: "2024-09-03T09:49:03.901Z",
    orderNumber: "20240903-KYC3",
    allowCounterOffers: true,
    canCounter: true,
    type: OrderType.Limit,
    price: {
      currencyCode: CurrencyCode.Usd,
      amount: "1100",
    },
    updatedOn: "2024-09-03T09:49:04.415Z",
    attributes: {
      vintages: ["2021-BACK_HALF"],
      locations: ["BC"],
      fuelSources: ["SO1"],
      eligibilities: [],
      projects: ["zazyrovozqsj4p8pbn29bzsqvxymywmt"],
      certifications: ["ecologo"],
      commencementDate: "2003-09-16",
    },
    vintage: "",
    vintages: ["2021 - BH"],
    eligibilities: [],
    location: "-",
    locations: ["British Columbia"],
    fuelSources: ["Wind"],
    project: "-",
    projects: ["runny industrialisation"],
    certifications: ["Ecologo Certified"],
    certificationsAndEligibilities: [
      {
        label: "certifications",
        value: ["Ecologo Certified"],
      },
      {
        label: "eligibilities",
        value: [],
      },
    ],
  },
  {
    id: "dd9b0dc3-af67b906bf68",
    tenantId: "08cd2505-18d4-488b-a960-2195463332e8",
    accountId: "25ec0a20-7147-22e3467",
    assetType: AssetType.Rec,
    position: OrderPosition.Ask,
    status: OrderStatus.Open,
    initialVolume: 19,
    currentVolume: 19,
    completedOn: null,
    createdOn: "2024-08-30T03:58:26.545Z",
    orderNumber: "20240830-EVAF",
    allowCounterOffers: true,
    canCounter: true,
    type: OrderType.Aon,
    price: {
      currencyCode: CurrencyCode.Usd,
      amount: "300",
    },
    updatedOn: "2024-08-30T03:58:27.167Z",
    attributes: {
      vintages: ["2021-BACK_HALF"],
      locations: ["BC"],
      fuelSources: ["WND"],
      eligibilities: ["il_utility"],
      projects: ["hxa5xf1v8wvx5tcvez113jwxeto24q7h"],
      certifications: [],
      commencementDate: "2003-09-16",
    },
    vintage: "",
    vintages: ["2021 - BH"],
    eligibilities: ["Illinois Utility"],
    location: "-",
    locations: ["British Columbia"],
    fuelSources: ["Wind"],
    project: "-",
    projects: ["secret evidence"],
    certifications: [],
    certificationsAndEligibilities: [
      {
        label: "certifications",
        value: [],
      },
      {
        label: "eligibilities",
        value: ["Illinois Utility"],
      },
    ],
  },
];

export const listTableData = [
  {
    id: "ja3jwqwaj31-gfgf",
    receivedOn: "2024-08-19T03:36:22.882Z",
    counterNumber: "20240819-2NJP",
    status: "IN PROGRESS",
    expandedData: [
      {
        counterId: "hahuai-najj",
        counterOn: "2024-08-19T03:36:22.882Z",
        counteredVolume: "10",
        counteredPrice: "600",
        status: "REQUESTED",
      },
      {
        counterId: "hah-suai-najj",
        counterOn: "2024-08-19T03:36:22.882Z",
        counteredVolume: "10",
        counteredPrice: "500",
        status: "REJECTED",
      },
    ],
  },
  {
    id: "ja3jajwwew3ja-anlklan2",
    receivedOn: "2024-08-19T03:36:22.882Z",
    counterNumber: "20240820-3NJP",
    status: "IN PROGRESS",
    expandedData: [
      {
        counterId: "hahuaas-as-i-najj",
        counterOn: "2024-08-19T03:36:22.882Z",
        counteredVolume: "10",
        counteredPrice: "6",
        status: "REQUESTED",
      },
    ],
  },
  {
    id: "ja3jaj3ja-12asa12n2",
    receivedOn: "2024-08-18T03:36:22.882Z",
    counterNumber: "20240818-3NJP",
    status: "ACCEPTED",
    expandedData: [
      {
        counterId: "hsas-a-ahuai-najj",
        counterOn: "2024-08-19T03:36:22.882Z",
        counteredVolume: "10",
        counteredPrice: "6",
        status: "ACCEPTED",
      },
    ],
  },
  {
    id: "ja3asawwsjaj6ja-1262n2",
    receivedOn: "2024-08-17T03:36:22.882Z",
    counterNumber: "20240817-3NJP",
    status: "REJECTED",
    expandedData: [
      {
        counterId: "hahas-as-uai-najj",
        counteredVolume: "10",
        counterOn: "2024-08-19T03:36:22.882Z",
        counteredPrice: "6",
        status: "REJECTED",
      },
    ],
  },
];

export const offerGroupsData = [
  {
    tenantId: "08cd2505-18d4-48463332e8",
    offerGroupNumber: "OFG1J2ONAVZOG",
    expandedData: "OFG1J2ONAVZOG",
    accountId: "25ec0a20-7147-42f22e3467",
    sourceOrderNumber: "20240903-KYC3",
    issuedOrderNumber: "20240903-KYC3",
    status: OfferGroupStatus.InProgress,
    createdOn: "2024-10-22T03:11:47.274Z",
  },
  {
    tenantId: "08cd2506-18d4-48463332e8",
    offerGroupNumber: "1FG1J2ONAVZOG",
    expandedData: "1FG1J2ONAVZOG",
    accountId: "27ec0a20-7147-42f22e3467",
    sourceOrderNumber: "20240903-KYC8",
    issuedOrderNumber: "20240903-KYC8",
    status: OfferGroupStatus.Accepted,
    createdOn: "2024-10-24T02:15:47.274Z",
  },
  {
    tenantId: "08cd257-18d4-48463332e8",
    offerGroupNumber: "2FG1J2ONAVZOG",
    expandedData: "2FG1J2ONAVZOG",
    accountId: "26ec0a20-7147-42f22e3467",
    sourceOrderNumber: "20240903-KYC4",
    issuedOrderNumber: "20240903-KYC4",
    status: OfferGroupStatus.ExecutionFailed,
    createdOn: "2024-10-21T04:11:47.274Z",
  },
  {
    tenantId: "08cd257-13d4-48463332e8",
    offerGroupNumber: "2FG1KONAVZOG",
    expandedData: "2FG1KONAVZOG",
    accountId: "29ec0a20-7147-42f22e3467",
    sourceOrderNumber: "20240903-KYC6",
    issuedOrderNumber: "20240903-KYC46",
    status: OfferGroupStatus.Withdrawn,
    createdOn: "2024-10-21T04:11:47.274Z",
  },
];
