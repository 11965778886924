import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { CounterOrderDirection, useOfferGroupsQuery } from "@/app/types/generated/graphql";

import { CounteredOrdersData, GoBackPaths } from "./common-counter-offers.type";

export const useCommonCounterOffersList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const counteredRECS: CounteredOrdersData = location.state?.selectedRecs;
  const counterOrderDirection: CounterOrderDirection = location.state?.counterOrderDirection;

  const { data, loading, refetch } = useOfferGroupsQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        orderNumber: counteredRECS?.orderNumber,
      },
    },
  });
  const goBack = useCallback(() => {
    navigate(GoBackPaths[counterOrderDirection]);
  }, [navigate, counterOrderDirection]);

  const reloadOfferGroups = useCallback(() => {
    refetch();
  }, [refetch]);

  return {
    tableData: data?.offerGroups ?? [],
    counteredRECS,
    goBack,
    loading,
    reloadOfferGroups,
  };
};
