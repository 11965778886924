import { PageInfo, TableFetchData } from "@powerledger/ui-component-lib";
import { Column } from "react-table";

import { ReturnTypeFormatAttributes } from "@/app/lib/format-attributes";
import { RECOrderAttributeOptions } from "@/app/lib/format-rec-options";
import { OfferGroup, Order, RecOrderAttributes } from "@/app/types/generated/graphql";

import { MarketTableData } from "../../markets/market.types";

export type CounteredOrdersData = Omit<Order, "attributes"> & {
  attributes: RecOrderAttributes;
} & ReturnTypeFormatAttributes;

export type CounterOfferProps = {
  showAllAttributes: boolean;
  setShowAllAttributes: React.Dispatch<React.SetStateAction<boolean>>;
  recOptions?: RECOrderAttributeOptions;
  tableData: CounteredOrdersData[];
  titles: string;
  userMessage: string;
  loading: boolean;
  onSeeCounterOffers: (recs: CounteredOrdersData) => void;
  tableColumns: Column<MarketTableData>[];
  reloadCounteredOrders: () => void;
  fetchData: TableFetchData<CounteredOrdersData>;
  pageInfo: PageInfo;
};

export type CounterOfferListProps = {
  tableData: OfferGroup[];
  counteredRECS: CounteredOrdersData;
  goBack: () => void;
  loading: boolean;
  reloadOfferGroups: () => void;
};

export type TableDataProps = {
  id: string;
  receivedOn: string;
  counterNumber: string;
  status: string;
};

export type AddDataProps = {
  expandedData: Array<any>;
};

export type ChipVariantType = "REQUESTED" | "REJECTED" | "default";

export type chipVariantProps = Record<ChipVariantType, "info" | "error" | "primary">;

export enum OffersTitle {
  INCOMING = "Received Counter Offers",
  OUTGOING = "My Counter Offers",
}

export enum OffersPageDesc {
  INCOMING = "Here is the list of sell orders that have received counter offers.",
  OUTGOING = "Here is the list of sell orders, you have submitted counter offers for.",
}

export enum RoutePaths {
  INCOMING = "/counter/received-offers/offers",
  OUTGOING = "/counter/my-offers/offers",
}

export enum GoBackPaths {
  INCOMING = "/counter/received-offers",
  OUTGOING = "/counter/my-offers",
}
