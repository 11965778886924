import { CounterOrderDirection } from "@/app/types/generated/graphql";

import { CounterOffersView } from "../common/counter-offers-view";
import { useCommonCounterOffers } from "../common/use-common-counter-offers";

export const ReceivedCounterOffers = () => {
  const logic = useCommonCounterOffers(CounterOrderDirection.Incoming);

  return <CounterOffersView {...logic} />;
};
